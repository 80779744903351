a {
	color: $colorGreen;
	text-decoration: none;
}

.links {
	display: flex;
	margin-left: -$base2; // horizontal gutter
	margin-top: ($base2 - $base2);	// real marginTop - vertical gutter
	flex-wrap: wrap;

	.block--hAlignBegin & {
		justify-content: flex-start;
	}

	.block--hAlignEnd & {
		justify-content: flex-end;
	}

	.block--hAlignCenter & {
		justify-content: center;
	}


	&__item {
		margin-left: $base2;
		margin-top: $base2;
	}
}
