.slideshow {
	//overflow: hidden;

	&__items {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		touch-action: pan-y;
		width: 100%;
	}


	&__itemsContainer {
		display: flex;
		width: 100%;
		min-height: 400px;


		@include media($columnsBreakpoint) {
			// just an example, it can be smaller
			width: 100%;
		}

		.slideshow--minHeightFixed & {
			min-height: $slideMinHeight;
		}

		.slideshow--minHeightFixedRatio & {
			@include minAspectRatio($slideRatio);
		}
	}
}
