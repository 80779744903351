.bildspalte {
	position: relative;
	width: 100%;
	margin-top: 40px;

	&__item {
		width: 100%;
		position: relative;
		transition: 0.3s all;
		@include media($smallBreakpoint) {
			width: 100%;
		}
		@include media($mediumBreakpoint) {
			width: 427.96px;
		}
	}

	&__title {
		font-size: 24px;
		line-height: 30px;
		letter-spacing: 0.428571px;
		color: $colorGreen;

		&_active {
			color: $colorInverted;
			z-index: 1;
		}
	}

	&__content {
		position: relative;
		width: 100%;
		height: auto;
		min-height: 400px;
		padding: 40px 20px;
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -2;
	}

	&__text {
		opacity: 0;
		transition: 0.3s all;
		font-size: 18px;
		line-height: 32px;
		margin-bottom: 40px;

		a {
			color: $colorInverted;
			text-decoration: underline;
		}

		&_active {
			opacity: 1;
			transition: 0.3s all;
			color: $colorInverted;
		}
	}

	&__background {
		background-color: #008a55da;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scaleX(1.07) scaleY(1.01);
		@include media($smallBreakpoint) {
			transform: translate(-50%, -50%) scaleX(1.07) scaleY(1.01);
		}
		@include media($mediumBreakpoint) {
			transform: translate(-50%, -50%) scaleX(1.07) scaleY(1.05);
		}
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: 0.3s all;
		z-index: -1;

		&_active {
			opacity: 1;
			transition: 0.3s all;
		}
	}

	&__link {
		opacity: 0;
		position: absolute;
		bottom: 20px;

		&_active {
			a, span {
				font-size: 21px;
				line-height: 25px;
				letter-spacing: 2px;
				color: #F1EAE8;
				font-style: italic;
			}
			opacity: 1;
			z-index: 1;
		}
	}
}
