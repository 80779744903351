.video {
	@mixin hideVideoCoverWhenReady() {
		@include ease(opacity visibility, $duration2);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.video.js-loading & {
			@include fadeOut();
		}

		.video.js-loaded & {
			display: none;
		}
	}


	&__caption {
		@include font(caption);
		margin-left: auto;
		margin-top: $base;

		.blocks:not(.blocks--nested) > .block--video.block--hSizeFull & {
			@include app-container();
		}
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 24px;
		max-width: 585px;
		text-align: right;
		letter-spacing: 2px;
		padding-right: 10px;
		@include media ($smallBreakpoint) {
			padding-right: 0;
		}
	}


	&__content {
		@include aspectRatio($videoRatio);
		position: relative;

		// useful to test the source switch of self-hosted video player
		// @include media('portrait') {
		// 	@include aspectRatio(1 / $videoRatio);
		// }
	}


	&__icon {
		height: $base4;
		left: 50%;
		position: absolute;
		top: 50%;
		width: $base4;
		margin-left: -$base2;
		margin-top: -$base2;


		[data-type="svg"] {
			width: 100%;
			height: 100%;

			svg {
				fill: $colorGreen;
			}
		}
	}


	&__img {
		@include hideVideoCoverWhenReady();
		@include objectFit(cover);
	}


	&__link {
		@include hideVideoCoverWhenReady();
		cursor: pointer;
	}
}
