.eyecatcher {
	min-height: 641px;
	display: flex;
	justify-content: center;
	align-items: center;

	&__text {
		font-style: normal;
		font-weight: normal;
		line-height: 30px;
		letter-spacing: 3px;
		font-size: calcFluidFontSize(21, 24, 360, 1440);
		text-align: center;
	}
}

@media (min-width: 1440px) {
	.eyecatcher {
		&__text {
			font-size: 24px;
		}
	}
}
