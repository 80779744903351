//bootstrap
// Custom.scss
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$grid-gutter-width: 4.2rem !default; //width column
$grid-breakpoints: (
	xs: 0,
	smm: 360px,
	sm: 700px,
	md: 760px,
	lg: 992px,
	xl: 1200px,
	xxl: 1440px
) !default;
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1361px,
) !default;


// 3. Include remainder of required Bootstrap stylesheets
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
@import "../../../../node_modules/bootstrap/scss/containers";
@import "../../../../node_modules/bootstrap/scss/grid";


//personal config
.row {
	margin-top: $marginTopRow;
}

.container {
	padding-left: 0;
	padding-right: 0;
}
@media (max-width: 1439px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg {

		max-width: none;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		padding-right: 4rem;
		padding-left: 4rem;
	}
}

@media (max-width: 576px) {
	.row {
		padding-right: 0;
		padding-left: 0;
	}

	.akkordeon__container {
		padding-left: 0;
	}
}
//@media (max-width: 360px) {
//	.container,
//	.container-sm,
//	.container-md,
//	.container-lg {
//		padding-right: 2rem;
//		padding-left: 2rem;
//	}
//}
