@use "sass:math";

$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;
$baseHalf: $base * 0.5;
$base25: $base * 2.5;

$videoRatio: math.div(16, 9);

$containerPaddingSmall: $base2;
// $containerPaddingMedium: $base5;
// $containerPaddingLarge: $base10;
$containerMaxWidth: 144.4 * $base;

$blockVerticalSpacing: $base4;
$firstBlockTopSpacing: $base8;
$teaserMinHeight: 80vh;
$teaserMinHeightIeFix: 79vh; // 1 unit less than the previous

$gutter: $base2;
$layoutGutter: $gutter;

$galleryJustifiedItemMinHeight: $base * 30;
$galleryColumnsNumber: 2;
$galleryColumnsImageRatio: 3 * 0.5;

$slideshowMaxItems: 20;

$slideMinHeight: 60vh;
$slideRatio: math.div(16, 9);
$slideGap: $gutter;



$gutterXS: $base * 2;
$gutterM: $base * 4;
$containerPaddingXS: $base4;
$containerPaddingM: $base4;


$inputPadding: $base * 0.65;
$inputNestedPadding: $inputPadding - 0.5;
$labelNestedPadding: $base * 0.4;
$inputToLabelVerticalMargin: $baseHalf;
$inputBorderSize: 1px;
$buttonBorderSize: $inputBorderSize;
$buttonMinWidth: $base * 16;
$radioOuterSize: $base2;
$radioInnerSize: $base * 1.2;
$radioInnerScale: 0.5;
$boxBorderSize: 2px;

$formFieldFullColumns: 8; // number of columns, 12 = full width

$mapHeightSmall: 50 * $base;
$mapHeightLarge: 50 * $base;

$hrSize: 1px;
$textMaxWidth: 100%;


$sizeVariants: (
);

// $gutterXS: $base * 1.5;
// $gutterS: $base * 1.8;
// $gutterM: $base * 2.1;
// $gutterL: $base * 2.2;



//margins
$marginTopFooter: 148px; // margin top footer
$marginTopRow: 20px;  // margin  top row (bootstrap)
$marginTopTitle: 64px;
$heightSlide: 442px;

