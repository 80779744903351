.arrowTop {
	position: fixed;
	bottom: 61px;
	width: 100%;
	height: 0;
	z-index: 10;

	&__wrapper {
		position: relative;
	}

	&__svg {
		position: absolute;
		right: 18px;
		bottom: 61px;
		opacity: 0;
		transition: 0.3s all;
		cursor: default;

		&_active {
			opacity: 50%;
			transition: 0.3s all;
			cursor: pointer;
		}
	}
}
