.slideshowNavigation {
	margin-top: 20px;

	&__arrows {
		display: flex;
		margin-bottom: $base;
		// margin-right: 2rem;
		justify-content: flex-end;
	}


	&__triggers {
		display: flex;
		margin-top: $base;
	}


	&__trigger {
		flex-grow: 0;

		&:not(:first-child) {
			margin-left: $base;
		}
	}
}
