$colorBase: #000000;
$colorBgBase: #ffffff;
$colorInverted: $colorBgBase;
$colorBgInverted: $colorBase;
$colorAlternative: #333333;
$colorBgAlternative: #AAAAAA;
$colorActiveLink: #008A55;
$colorBgModal: #CCCCCC;
$colorMenu: #F0EAE8;
$colorGreen: #008351;
$colorTextBlack: #0000;


$colorInput: $colorBase;
$colorBgInput: transparent;
$colorInputBorder: $colorBase;
$colorError: #FF0000;

$colorBgModalCover: transparentize($colorBgInverted, 0.4);

