
.grid {
	background-color: rgba(0, 0, 0, 0.015);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 20;
	pointer-events: none;

	&.js-active {
		display: block;
	}

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.025;
		background-position: top;
		background-attachment: fixed;
		background-image: repeating-linear-gradient(180deg, #fff 0, #fff 0.25 * 10rem, #000 0.25 * 10rem, #000 0.5 * 10rem);

		@include media($mediumBreakpoint) {
			background-image: repeating-linear-gradient(180deg, #fff 0, #fff 0.25 * 12.8rem, #000 0.25 * 12.8rem, #000 0.5 * 12.8rem);
		}
	}

	&__content {
		@include app-container();
		max-width: $containerMaxWidth;
		margin-left: auto;
		margin-right: auto;
		height: 100%;
		background-color: rgba(0, 244, 0, 0.01);
	}

	&__columns {
		@include staticColumns();
		height: 100%;
	}

	&__column {
		background-color: rgba(255, 0, 0, 0.05);
		width: staticColumnSize(1, 6, $gutterXS);
		height: 100%;

		&:nth-child(n+7) {
			display: none;
		}

		@include media($mediumBreakpoint) {
			&:nth-child(n+7) {
				display: block;
			}

			width: staticColumnSize(1, 12, $gutterM);
		}
	}
}
