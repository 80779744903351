
.gallery {
	&__title {
		color: $colorGreen;
		font-style: normal;
		font-weight: normal;
		font-size: calcFluidFontSize(21, 24, 360, 1440);
		line-height: 32px;
		word-wrap: break-word;
	}

	&__text {
		margin-top: 50px;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 32px;
		color: #090A09;
		letter-spacing: 1.1px;
		word-wrap: break-word;

		&_reset-margin {
			margin-top: 0;
		}
	}

	&__link {
		margin-top: 50px;

		a {
			font-size: 21px;
			line-height: 25px;
			letter-spacing: 1px;
			font-style: italic;
			text-decoration: none;
			color: $colorGreen;
		}
	}

	&__slideshow {
		overflow: hidden;
		visibility: hidden;
		position: relative;

		//&__wrapper {
		//	overflow: hidden;
		//	position: relative;

		//	div {
		//		float: left;
		//		width: 100%;
		//		position: relative;
		//		overflow: hidden;
		//	}
		//}
	}
}


@media (min-width: 1440px) {
	.gallery {
		&__title {
			font-size: 24px;
		}
	}
}
