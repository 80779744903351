.akkordeon {
	&__arrow {
		svg {
			transition: 0.3s all;
		}
	}

	&__btn {
		cursor: pointer;
		transform: rotate(0deg);
		transition: 0.3s all;

		&_active {
			transform: rotate(-180deg);
		}
	}

	&__head {
		cursor: pointer;
		width: 100%;
		min-height: 48px;
		gap: 40px;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		background-color: $colorGreen;
		&:hover {
			.akkordeon__btn {
				transform: rotate(-90deg);
				&_active {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__question {
		color: #f1eae8;
		font-size: 18px;
		line-height: 32px;
		letter-spacing: 1.1px;
		font-style: normal;
		font-weight: normal;
		text-align: right;
	}

	&__body {
		background-color: #f1eae8;
		padding-top: 32px;
		padding-left: 34px;
		padding-right: 22px;
		padding-bottom: 34px;

		&_active {
			transition: 0.3s all;
			display: flex;
			justify-content: center;
			flex-direction: column;
		}
		display: none;
	}

	&__title {
		font-size: 24px;
		line-height: 30px;
		letter-spacing: 3px;
		word-wrap: break-word;
		font-style: normal;
		font-weight: normal;
	}

	&__answer {
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.8px;
		word-wrap: break-word;
		color: #090a09;
		overflow: hidden;
		font-style: normal;
		font-weight: normal;
		p {
			margin-top: $base2;
		}
	}

	&__link {
		margin-top: 57px;

		a {
			font-style: italic;
			color: $colorGreen;
			text-decoration: none;
			font-size: 21px;
			line-height: 25px;
			letter-spacing: 2px;
		}
	}
}
