.events {
	&__wrapperHead {
		display: flex;
		align-items: center;
	}

	&__row {
		align-items: center;
	}

	&__items {
		margin-top: 16px;
		@include media($mdiumBrealpointBot) {
			margin-top: 32px;
		}
	}

	&__labelInput {
		position: relative;
		width: 90%;
		height: 100%;
		display: flex;
		align-items: center;

		&::before {
			display: block;
			content: ' ';
			background-image: url('/assets/img/arrow-akordion.svg');
			background-size: 25px 12px;
			height: 12px;
			width: 25px;
			position: absolute;
			left: 0;
			cursor: pointer;
		}
	}

	&__inputDate {
		width: auto;
		position: absolute;
		right: 0;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 32px;
		letter-spacing: 1.1px;
		color: #FFFFFF;
	}

	&__akkordeon {
		background-color: $colorGreen;
		min-height: 48px;
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 50px;
		@include media($mdiumBrealpointBot) {
			margin-top: 0;
		}

		input {
			width: 100%;
			height: 100%;
			display: block;
			background-color: rgba(255, 255, 255, 0);
			outline: none;
			border: none;
			position: relative;
			text-align: right;
			opacity: 0;

			&::-webkit-calendar-picker-indicator {
				position: absolute;
				left: -27px;
				opacity: 1;
				cursor: pointer;
				font-size: 22px;
				display: block;
			}
		}
	}

	&__title {
		line-height: 32px;
		letter-spacing: 3px;
		color: $colorGreen;
		font-weight: normal;
		font-size: calcFluidFontSize(21, 24, 360, 1440);
		@include media ($largeBreakpoint) {
			font-size: 24px;
		}
	}

	&__image {
		display: block;
		width: 193px;
		max-height: 144px;
		object-fit: cover;

		&_rightAligin {
			margin: 0 auto;
			@include media($mdiumBrealpointBot) {
				margin: 0;
				margin-left: auto;
			}
		}

		&_leftAligin {
			margin-right: auto;
		}
	}

	&__line {
		width: 100%;
		height: 1px;
		background-color: $colorGreen;
		margin-top: 7px;

		&_mobile {
			display: block;
			@include media($mdiumBrealpointBot) {
				display: none;
			}
		}
	}

	&__date {
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.8px;
		text-transform: uppercase;
		color: $colorGreen;
	}

	&__titleItem {
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.8px;
		color: $colorGreen;
		text-transform: uppercase;
		margin-top: 8px;
		@include media($mdiumBrealpointBot) {
			margin-top: 0;
		}
	}

	&__topInfo {
		display: block;
		position: relative;
		grid-template-columns: 34.6% auto;
		gap: 40px;
		margin-top: 29px;
		@include media($mdiumBrealpointBot) {
			display: grid;
			margin-top: 0;
		}
	}

	&__bottonInfo {
		display: block;
		margin-top: 8px;
		position: relative;
		grid-template-columns: 34.6% auto;
		gap: 40px;
		@include media($mdiumBrealpointBot) {
			display: grid;
			margin-top: 12px;
		}
	}

	&__detailInfo {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.7px;
		color: $colorGreen;
	}

	&__descr {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 2px;
		color: #222221;
		margin-top: 8px;
		@include media($mdiumBrealpointBot) {
			margin-top: 0;
		}
	}
}

.event {
	display: flex  !important;

	&_active {
		display: flex !important;
	}
}
