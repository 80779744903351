.longText {
	&__title {
		font-style: normal;
		font-weight: normal;
		line-height: 32px;
		text-align: right;
		letter-spacing: 3px;
		color: $colorGreen;
		font-size: calcFluidFontSize(21, 24, 360, 1440);
		@include media($largeBreakpoint) {
			font-size: 24px;
		}
	}

	&__text {
		font-style: normal;
		font-weight: normal;
		margin-top: 20px;
		font-size: 18px;
		line-height: 32px;
		letter-spacing: 1.1px;
		color: #090A09;
		@include media($mdiumBrealpointBot) {
			margin-top: 0;
		}
	}
}
