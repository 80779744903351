.container_error {
	min-height: 600px;
	position: relative;

	h2 {
		width: 100%;
		position: absolute;
		text-align: center;
		top: 40%;
		transform: translateY(-40%);
		font-weight: normal;
	}
}
