
.text-bild {
	&__img {
		width: 100%;

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&__caption {
		text-align: right;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 2px;
	}

	&__text {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 32px;
		letter-spacing: 1.1px;
		word-wrap: break-word;
		margin-top: 39px;
		white-space: break-spaces;

		&_columns {
			column-count: 2;
			column-gap: 40px;
		}
	}

	&__title {
		font-style: normal;
		font-weight: normal;
		font-size: calcFluidFontSize(21, 24, 360, 1440);
		line-height: 32px;
		letter-spacing: 3px;
		color: $colorGreen;

		&_position-right {
			margin-top: 39px;
		}

		&_position-left {
			text-align: right;
		}
	}

	&__reset-margin {
		margin-top: 50px;
	}
}
@media (min-width: 992px) {
	.text-bild__reset-margin {
		margin-top: 0;
	}
}
@media (min-width: 1440px) {
	.text-bild {
		&__title {
			font-size: 24px;
		}
	}
}
@media (max-width: 1200px) {
	.text-bild__text_columns {
		column-count: 1;
	}

	.text-bild__text_default {
		width: 100%;
	}
}
