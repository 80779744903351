.field {
	@include font(base);
	width: 100%;

	&--disabled {
		opacity: $opacityInputDisabled;
	}

	&--bookingCheck {
		width: 50%;

		@include media($columnsBreakpoint) {
			width: 100%;
		}

		.booking__check--out & {
			margin-left: 50%;

			@include media($columnsBreakpoint) {
				margin-left: 0;
			}
		}
	}

	.form & {
		width: dynamicColumnSize(1, 1, $gutter);

		@include media($columnsBreakpoint) {
			width: dynamicColumnSize(1, 2, $gutter);
		}

		&--full {
			@include media($columnsBreakpoint) {
				width: dynamicColumnSize(1, 1, $gutter);
			}
		}

		&--alone {
			@include media($columnsBreakpoint) {
				margin-right: dynamicColumnPush(1, 2, $gutter, true);
			}
		}
	}

	.form__separator + & {
		margin-top: $base4;
	}

	.form__separator + &:not(&--full):not(&--alone) + &:not(&--full):not(&--alone) {
		@include media($columnsBreakpoint) {
			margin-top: $base4;
		}
	}

	// nested
	.field__fields > & {
		width: dynamicColumnSize(1, 1, $gutter);
	}

	// nested
	.field__fields--horizontal > & {
		@include media($columnsBreakpoint) {
			display: inline-block;
			width: auto;
			margin-left: $base3;
			margin-top: 0;
		}

		.trackers & {
			margin-left: 0;
			width: staticColumnSize(1, 2, $gutter);
		}
	}

	.field__fields--grid > & {
		@include media($columnsBreakpoint) {
			width: dynamicColumnSize(1, 3, $gutter);
		}

		&--double {
			@include media($columnsBreakpoint) {
				width: dynamicColumnSize(2, 3, $gutter);
			}
		}
	}

	.field--checkboxes &,
	.field--radios & {
		margin-top: 0;
	}

	&__content {
		margin-left: -$base3;

		.field--bookingCheck & {
			margin-left: 0;
			position: relative;
		}

		.field--group > & {
			margin-left: 0;
		}
	}


	&__description {
		margin-left: $base3;

		&:not(&--extended) {
			@include font(fieldDescription);
			margin-top: $baseHalf;
		}

		.field--password &,
		.field--select &,
		.field--text &,
		.field--textarea & {
			margin-top: $baseHalf;
		}

		.field--checkbox &,
		.field--radio & {
			margin-left: $base6;
		}
	}


	&__errors {
		@include font(fieldError);
		color: $colorError;
		margin-left: $base3;
		margin-top: $baseHalf;
	}


	&__fields {
		@include dynamicColumns($hSpace: $gutter, $vSpace: $base3, $hSpacePos: left, $vSpacePos: top);
		position: relative;

		.field--checkboxes &,
		.field--radios & {
			margin-top: 0;
		}

		&--horizontal {
			@include media($columnsBreakpoint) {
				font-size: 0;
				display: block;
				margin-top: 0;
				margin-left: -$base3;
			}

			.trackers & {
				@include staticColumns();
				margin-left: 0;
			}
		}
	}


	&__info {
		color: $colorInput;
		display: block;

		&--strong {
			color: $colorBase;
		}

		.field--radio &,
		.field--checkbox & {
			margin-left: $base3;
		}

		.field__label + & {
			margin-top: -$base;

			@include media($columnsBreakpoint) {
				margin-top: 0;
			}
		}

		.field__inputWrapper--extended & {
			@include media($columnsBreakpoint) {
				padding: $inputPadding 0;
				margin-left: 0;
				width: dynamicColumnSize(1, 6, $gutter);
			}

			&--compact {
				@include media($columnsBreakpoint) {
					width: dynamicColumnSize(2, 12, $gutter);
				}
			}

			&--right {
				@include media($columnsBreakpoint) {
					text-align: right;
				}
			}

			&--first {
				color: $colorBase;
				margin-left: $base3;
				flex-grow: 1;
			}
		}
	}


	&__input {
		@include font(base);
		background-color: $colorBgInput;
		color: $colorInput;

		&:focus {
			outline-color: currentColor;
			outline-style: solid;
			outline-width: $inputBorderSize;
		}

		&--bookingCheck {
			height: 100%;
			left: 0;
			opacity: 0;
			pointer-events: all;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
			-webkit-appearance: none;

			@include media($enoughHeightBreakpoint) {
				@include visuallyHidden();
				pointer-events: none;
				visibility: hidden;
			}
		}

		&--password,
		&--text {
			background-color: $colorBgBase;
			border: $inputBorderSize solid $colorInputBorder;
			padding: $inputPadding;
			width: 100%;

			.field--invalid & {
				border-color: $colorError;
			}

			.field--full & {
				@include media($columnsBreakpoint) {
					max-width: staticColumnSize($formFieldFullColumns, 12, $base2);
				}
			}

			.field--full .field__fields & {
				@include media($columnsBreakpoint) {
					max-width: none;
				}
			}

			.field__label--radioText & {
				padding: $inputNestedPadding $inputPadding;
			}

			.field--search & {
				max-width: none;
				width: auto;
				flex-grow: 1;
				flex-shrink: 1;
			}

			.field--bookingAmount & {
				background-color: transparent;
				border-radius: 0;
				text-align: center;
				// -moz-appearance: textfield;
				-webkit-appearance: textfield;


				::placeholder {
					text-align: center;
				}


				&::-webkit-inner-spin-button,
				&::-webkit-outer-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}

		&--textarea {
			background-color: $colorBgBase;
			border: $inputBorderSize solid $colorInputBorder;
			min-height: $base2 + getFontSize(base) * getLineHeight(base) * 4;
			padding: $base;
			width: 100%;

			.field--full & {
				@include media($columnsBreakpoint) {
					max-width: staticColumnSize($formFieldFullColumns, 12, $base2);
				}
			}

			.field--invalid & {
				border-color: $colorError;
			}
		}

		&--select {
			background-color: transparent;
			border: $inputBorderSize solid $colorInputBorder;
			// needed because on OSX to reset the appearance is not enough
			border-radius: 0;
			min-width: $buttonMinWidth;
			padding: $inputPadding $base2 $inputPadding $inputPadding;
			position: relative;
			width: 100%;
			z-index: 2;
			appearance: none;

			&::-ms-expand {
				display: none;
			}

			.field--invalid & {
				border-color: $colorError;
			}
		}

		&--checkbox,
		&--radio {
			@include visuallyHidden();
		}
	}


	&__inputWrapper {
		margin-left: $base3;
		position: relative;

		.field--bookingCheck & {
			height: 100%;
			left: 0;
			margin: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}

		.field--select & {
			background-color: $colorBgBase;
		}

		&--extended {
			@include media($columnsBreakpoint) {
				@include dynamicColumns($hSpace: $gutter, $hSpacePos: right);
			}
		}

		.field--horizontal & {
			@include media($columnsBreakpoint) {
				display: inline-block;
			}
		}

		.field--full.field--select & {
			@include media($columnsBreakpoint) {
				max-width: staticColumnSize($formFieldFullColumns, 12, $base2, -$base2);
			}
		}

		.field--search & {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			@include media($columnsBreakpoint) {
				max-width: staticColumnSize($formFieldFullColumns, 12, $base2, -$base2);
			}
		}

		.field--radioText & {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			position: relative;
		}
	}


	&__label {
		@mixin replaceInput($circle: true) {
			&::before {
				border: $inputBorderSize solid $colorInputBorder;
				@if $circle {
					border-radius: 50%;
				}
				content: '';
				height: $radioOuterSize;
				left: -$base3;
				position: absolute;
				top: $base;
				vertical-align: middle;
				width: $radioOuterSize;
			}

			&::after {
				@include ease(opacity, $duration2);
				background-color: currentColor;
				@if $circle {
					border-radius: 50%;
				}
				content: '';
				height: $radioOuterSize; //$radioInnerSize;
				left: -$base3;// + (($radioOuterSize - $radioInnerSize) / 2);
				opacity: 0;
				position: absolute;
				top: $base;// + (($radioOuterSize - $radioInnerSize) / 2);
				transform: scale($radioInnerScale);
				vertical-align: middle;
				width: $radioOuterSize;//$radioInnerSize;
			}

			input:focus ~ & {
				outline-color: currentColor;
				outline-width: $inputBorderSize;
				outline-style: solid;
			}
		}

		@include font(base);
		border-color: transparent;
		border-style: solid;
		border-width: $inputBorderSize 0;
		color: $colorInput;
		display: block;
		margin-left: $base3;
		padding: $inputPadding 0;
		position: relative;

		&--strong {
			color: $colorBase;
		}

		&--important {
			@include font(baseBigger);
			font-weight: strong;
		}

		.field--bookingAmount & {
			@include visuallyHidden();
		}

		.field--bookingCheck & {
			@include font(base);
			border-color: currentColor;
			border-style: solid;
			border-width: $buttonBorderSize;
			display: block;
			margin: 0;
			padding: $inputPadding 0;
			pointer-events: none;
			position: relative;
			text-align: center;
			width: 100%;
			z-index: 2;

			@include media($enoughHeightBreakpoint) {
				border-bottom-width: 0;
			}

			.booking__check.js-active & {
				@include media($enoughHeightBreakpoint) {
					background-color: $colorBgBase;
				}

				@include media($columnsBreakpoint) {
					background-color: transparent;
				}
			}

			@include media($enoughHeightBreakpoint) {
				pointer-events: all;
			}

			@include media($columnsBreakpoint) {
				pointer-events: none;
			}

			.booking__check--out & {
				border-left-width: 0;

				@include media($columnsBreakpoint) {
					border-left-width: $buttonBorderSize;
				}
			}
		}

		.field--group > .field__content > & {
			margin-left: 0;
		}

		.field--password,
		.field--text & {
			display: block;
		}

		&--checkbox {
			input[type="checkbox"] + & {
				@include replaceInput($circle: false);
			}

			input[type="checkbox"]:checked + &::after {
				opacity: 1;
			}

			// input[type="checkbox"][disabled="disabled"] + &::before {
			// 	display: none;
			// }

			input[disabled] + &::before {
				background-color: $colorInputBorder;
			}

			input:focus + &::before {
				outline-color: currentColor;
				outline-offset: 1px;
				outline-width: $inputBorderSize;
				outline-style: dotted;
			}
		}

		&--radio {
			input[type="radio"] + & {
				@include replaceInput($circle: true);
			}

			input[type="radio"]:checked + &::after {
				opacity: 1;
			}

			input[disabled] + &::before {
				background-color: $colorInputBorder;
			}

			input:focus + &::before {
				outline-color: currentColor;
				outline-offset: 1px;
				outline-width: $inputBorderSize;
				outline-style: dotted;
			}

			.field--radioText & {
				bottom: 0;
				flex-grow: 0;
				left: 0;// $base3;
				padding-right: 0;
				padding-left: 0;
				position: absolute;
				right: 0;
				top: 0;
			}

			.field__inputWrapper--extended & {
				@include media($columnsBreakpoint) {
					flex-grow: 1;
				}
			}
		}

		&--radioText {
			padding: $labelNestedPadding 0;
			flex-grow: 1;
		}

		.field__inputWrapper--extended & {
			@include media($columnsBreakpoint) {
				width: dynamicColumnSize(6, 12, $gutter, -$base3);
			}
		}
	}


	&__labelValue {
		.field--required > .field__content > .field__label &::after,
		.field--required > .field__content > .field__inputWrapper > .field__label &::after {
			content: '*';
		}

		.field--radio &::before {
			content: '';
			position: absolute;
			height: 100%;
			width: $base3;
			left: -$base3;
			top: 0;
		}
	}


	&__selectIcon {
		color: $colorInput;
		position: absolute;
		right: $baseHalf;
		top: 50%;
		height: $base;
		width: $base;
		text-align: center;
		transform: translateY(-50%) rotate(90deg);
		z-index: 1;


		[data-type="svg"] {
			width: auto;
			height: 100%;
		}
	}

	&__text {
		margin-left: $base3;

		&--info {
			margin-top: $base3;
			margin-left: 0;
			margin-bottom: $base3;
		}
	}


	&__link {
		display: block;
		font-weight: bold;
		margin-left: $base3;
		padding-bottom: $base1;
		text-decoration: none;

		@include onHover {
			color: $colorBase;
		}
	}
}
