.mapBox {
	height: $mapHeightSmall;
	position: relative;
	width: 100%;

	@include media($columnsBreakpoint) {
		height: $mapHeightLarge;
	}


	&__loading {
		@include ease(opacity visibility, $duration2);
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;

		.block--map.block--colorSchemeDefault & {
			background-color: $colorBgBase;
			color: $colorBase;
		}

		.block--map.block--colorSchemeInverted &,
		.block--layout.block--colorSchemeInverted .block--map.block--colorSchemeDefault & {
			background-color: $colorBgInverted;
			color: $colorInverted;
		}

		.block--map.block--colorSchemeAlternative &,
		.block--layout.block--colorSchemeAlternative .block--map.block--colorSchemeDefault & {
			background-color: $colorBgAlternative;
		}


		.googleMap.js-loaded ~ & {
			@include fadeOut();
		}
	}


	&__loadingLabel {
		@include font(base);
	}
}
