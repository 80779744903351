.spacer {
	height: 81px;
}
@media (min-width: 1440px) {
	.spacer {
		height: 81px;
	}
}
@media (max-width: 1440px) {
	.spacer {
		height: 80px;
	}
}
@media (max-width: 576px) {
	.spacer {
		height: 64px;
	}
}
