.footer {
	margin-top: $marginTopFooter;
	padding-bottom: 30px;
	padding-top: 30px;

	&.js-covered {
		@include app-covered();
	}

	.container {
		.row:nth-child(1) {
			div:nth-child(1) {
				overflow: hidden;
				position: relative;
			}
		}
	}
	background-color: $colorGreen;
	min-height: 336px;
	color: #fff;
	width: 100%;

	&__logo {
		width: 100%;
		display: block;

		&-svg {
			span {
				width: 100%;
			}
		}
	}

	&__items {
		padding-top: 2px;
		word-wrap: break-word;
	}

	&__item {
		margin-top: 7px;

		a {
			color: #F1EAE8;
			text-decoration: none;
			font-size: 18px;
			line-height: 23px;
			letter-spacing: 0.321429px;
			font-style: normal;
			font-weight: normal;
			transition: 0.3s all;

			&:hover {
				color: gray;
			}
		}
	}

	&__offset {
		display: none;
	}

	&__year {
		display: block;
		margin-top: 38px;
		font-style: normal;
		font-weight: normal;
	}
}
@media (max-width: 759px) {
	.footer__column {
		margin-top: 40px;
	}

	.footer__column:nth-child(1) {
		margin-top: 89px;
	}

	.footer__year {
		margin-top: 40px;
		margin-bottom: 15px;
	}
}
@media (min-width: 701px) and (max-width: 760px) {
	.footer__offset {
		display: block;
	}
}
