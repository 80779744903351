.menu {
	&__items {
		margin-top: 71px;
		word-wrap: break-word;
	}

	&__link {
		text-decoration: none;
		font-size: 24px;
		line-height: 32px;
		color: black;
		transition: 0.3s all;
		letter-spacing: 6px;

		&:hover {
			color: green;
		}
	}

	&__item {
		margin-top: 43px;

		&--subpage {
			margin-top: 23px;

			.menu__link {
				font-size: 20px;
				letter-spacing: 3px;
				padding-left: 23px;
			}
		}
	}
}
