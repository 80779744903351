.title {
	width: 100%;
	margin-top: $marginTopTitle;

	&__h1 {
		font-size: calcFluidFontSize(24, 32, 360, 1440);
		font-style: normal;
		font-weight: normal;
		line-height: 40px;
		letter-spacing: 3px;
		color: $colorGreen;
		white-space: break-spaces;

		&_full-size {
			width: 100%;
		}

		&_right-column {
			text-align: left;
		}
	}

	&__h2 {
		font-style: normal;
		font-weight: normal;
		line-height: 30px;
		letter-spacing: 3px;
		white-space: break-spaces;
		font-size: calcFluidFontSize(21, 24, 360, 1440);
		color: #008A55;

		&_left-column {
			text-align: right;
		}

		&_right-column {
			text-align: left;
		}

		&_full-centered {
			width: 100%;
			text-align: center;
		}
	}
}
@media (min-width: 1400px) {
	.title {
		&__h1 {
			font-size: 32px;
		}

		&__h2 {
			font-size: 24px;
		}
	}
}
