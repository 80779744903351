// Style for User Generated Content
.ugc {
	white-space: initial;

	a {
		@include app-link();
	}


	q,
	blockquote {
		quotes: "\00BB" "\00AB";
	}


	blockquote {
		@include font(quote);
		margin: 0;
		padding-left: $base2;
		border-left: 1px solid currentColor;

		> p:first-child::before {
			content: open-quote;
		}

		> p:last-of-type::after {
			content: close-quote;
		}
	}


	cite {
		@include font(base);
		display: block;
		font-style: normal;
		text-align: right;


		&::before {
			content: '\2014';
			margin-right: 0.2em;
		}
	}

	blockquote cite {
		margin-top: $base;
	}

	q + cite {
		display: inline;
		margin-left: $base;
	}


	q {
		font-style: italic;
	}


	dl {
		margin: 0;
	}


	dd {
		margin: 0;
	}


	dt {
		@include font(base);
		display: block;
		font-weight: bold;
	}


	h1 {
		@include font(subtitle);
		color: $colorGreen;
		white-space: pre-wrap;
	}


	h2 {
		@include font(subtitle);
		color: $colorGreen;
		white-space: pre-wrap;
	}


	h3 {
		@include font(subtitle);
		color: $colorBase;
		white-space: pre-wrap;
	}

	h4 {
		@include font(base);
		font-weight: bold;
		color: $colorBase;
		white-space: pre-wrap;
	}


	h4,
	h5,
	h6 {
		@include font(subtitle);
		color: $colorGreen;
		white-space: pre-wrap;
	}


	hr {
		background-color: currentColor;
		border: 0;
		height: $hrSize;
		margin: 0;
		width: 100%;
	}


	li {
		margin: 0;
	}

	ol > li {
		padding-left: 0.4em;

		&::marker {
			color: $colorGreen;
		}
	}


	ol {
		margin: 0 0 0 1em;
	}


	ul {
		margin: 0 0 0 $base2;
	}

	ul > li {
		position: relative;

		&::before {
			content: '\2022';
			position: absolute;
			top: -1;
			transform-origin: 0 50%;
			transform: scale(0.6);
			left: -$base2;
			color: $colorGreen;
		}
	}


	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ol,
	ul,
	dl,
	blockquote {
		max-width: $textMaxWidth;
		white-space: pre-wrap;
	}


	* + a > figure {
		margin-top: 1em;
	}


	// space before the headings
	* + h1,
	* + h2,
	* + h3,
	* + h4,
	* + h5,
	* + h6 {
		margin-top: $base3;
	}


	// space before all common elements in the content
	* + table,
	* + figure,
	* + p,
	* + ol,
	* + ul,
	* + dl,
	dd + dd,
	dt + dt {
		margin-top: 1em;
	}


	// space between list items
	li + li {
		margin-top: $base;
	}


	// special style between definition list items
	dd + dt {
		margin-top: $base2;
	}


	// special margin around the blockquote
	* + blockquote,
	blockquote + *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
		margin-top: $base2;
	}

	// special margin around hr separator
	* + hr,
	hr + * {
		margin-top: $base2;
	}
}
