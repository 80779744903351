.logo-big {
	margin-top: 20px;
	margin-bottom: 20px;

	&__svg {
		display: block;
		overflow: hidden;
		width: 100%;
	}

	canvas {
		height: 100%;
		width: 100%;
	}

	[data-type="svg"] {
		width: 100%;
	}
}
