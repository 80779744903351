//img {
//	cursor: zoom-in;
//}

.zoomIn {
	cursor: zoom-in;
	transition: 0.3s all;
}

.zoomOut {
	cursor: zoom-out;
	transition: 0.3s all;
	transform: scale(1.3);
}
