
@mixin app-toggleContainer($apply: true) {
	padding-left: if($apply, $containerPaddingXS, 0);
	padding-right: if($apply, $containerPaddingXS, 0);
	margin-left: auto;
	margin-right: auto;
	max-width: if($apply, $containerMaxWidth, none);
	width: 100%;

	@include media($mediumBreakpoint) {
		padding-left: if($apply, $containerPaddingM, 0);
		padding-right: if($apply, $containerPaddingM, 0);
	}

	// @include media($largeLayout) {
	// 	padding-left: if($apply, $containerPaddingLarge, 0);
	// 	padding-right: if($apply, $containerPaddingLarge, 0);
	// }
}
