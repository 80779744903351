// the regular version is directly requested in the <head> in order to prefetch the file
@font-face {
	font-family: 'Supreme';
	src:
		url('../fonts/SupremeLL-Book.woff2') format('woff2'),
		url('../fonts/SupremeLL-Book.woff') format('woff');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Supreme';
	src:
		url('../fonts/SupremeLL-BookItalic.woff2') format('woff2'),
		url('../fonts/SupremeLL-BookItalic.woff') format('woff');
	font-style: italic;
	font-weight: normal;
}
